function newPath (path, mathBox){
    // set cursor at first box
    // path structure: [row column ?smallbox column? ...] + [thissmallbox thiscolumn]
    var pathDepth = path.length;
    path[pathDepth-1] = path[pathDepth-1] - 1 //move cursor one step left to new object
    if (mathBox) {path.push(0, 0)} //extra depth
    path.push(1, 0) // set thissmallbox to 1 (first box in new object NB: assume image is element 0) and thiscolumn to 0
    return path
}

export default newPath