import colorToHex from '../ui/colortohex';
import makesvg from '../ui/makeSVG';


function newAttributesEventIcon(data, viewWriter, viewNode, latexName){
    if (data.attributeKey === 'fontColor'){
        if (data.attributeNewValue) {
            const newColor = colorToHex(data.attributeNewValue);
            console.log("#NEW COLOR:", newColor);
            const imgColorLatexName = '\\color{'+newColor+'}'+ latexName;
            const newImgSRC = makesvg(imgColorLatexName);
            viewWriter.setAttribute( 'src', newImgSRC, viewNode );
        }
    } else {
    if (data.attributeKey === 'fontSize'){
        if (data.attributeNewValue) {
            const newSize = data.attributeNewValue;
            console.log("#NEW SIZE:", newSize);
            console.log("DATA:", data);
            viewWriter.setAttribute( 'style', 'height:'+newSize, viewNode);
        }

    }
    }
}


function iconConv (thiseditor, className, latexName, toolbarName, imgSRC) {
    const modelName = toolbarName + '-icon';

    thiseditor.conversion.for( 'upcast' ).elementToElement( {
        view: {
            name: 'img',
            class: className,
            src: imgSRC
        },
        model: ( viewElement, { writer: modelWriter } ) => {
            return modelWriter.createElement( modelName, {
                class: className,
                src: imgSRC
            } );
        }
    } );


    thiseditor.conversion.for( 'downcast' ).elementToElement( {
        model: modelName,
        view: ( modelValue, { writer: viewWriter } ) => {
            return viewWriter.createEmptyElement( 'img',
            {
                class: className,
                src: imgSRC
            } );
        },
    } );

    thiseditor.conversion.for( 'downcast' ).add( dispatcher => {
        dispatcher.on( 'attribute', ( evt, data, conversionApi ) => {
            // Convert <integralBox-icon> attributes only.
            if ( data.item.name != modelName ) {
                return;
            }
            console.log("NEW ATTRIBUTES!!!", data.attributeKey); //data.attributeNewValue

            const viewWriter = conversionApi.writer;
            const viewNode = conversionApi.mapper.toViewElement( data.item );

            // In the model-to-view conversion we convert changes.
            // An attribute can be added or removed or changed.
            // The below code handles all 3 cases.
            if ( data.attributeNewValue ) {
                viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, viewNode );
            } else {
                viewWriter.removeAttribute( data.attributeKey, viewNode );
            }

            newAttributesEventIcon(data, viewWriter, viewNode, latexName);


        } );
    } );

}

export default iconConv