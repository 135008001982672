import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import colorToHex from '../ui/colortohex';
import newStyle from '../ui/newstylestring';

function newAttributesEventContainer(data, viewWriter, viewNode){
    if (data.attributeKey === 'fontColor'){
        if (data.attributeNewValue) {
            console.log("Container new data:", data);
            console.log("Container new color:", data.attributeNewValue);
            const newColor = colorToHex(data.attributeNewValue);
            console.log("#NEW COLOR container:", newColor);
            console.log("ATTRS (col):", data.item._attrs.get('fontColor'));
            var map = data.item._attrs; //necessary because two style attributes can be present
            var newstyle = newStyle(map);

            viewWriter.setAttribute( 'style', newstyle, viewNode);
        }
    } else {
    if (data.attributeKey === 'fontSize'){
        if (data.attributeNewValue) {
            const newSize = data.attributeNewValue;
            console.log("#NEW SIZE container:", newSize);
            console.log("DATA container:", data);
            console.log("ATTRS (size):", data.item._attrs);
            var map = data.item._attrs;
            var newstyle = newStyle(map);

            viewWriter.setAttribute( 'style', newstyle, viewNode);
        }
    } else {
    if (data.attributeKey === 'fontBackgroundColor'){
        if (data.attributeNewValue) {
            console.log("Container new data:", data);
            console.log("Container new bg-color:", data.attributeNewValue);
            const newColor = colorToHex(data.attributeNewValue);
            console.log("#NEW bg-COLOR container:", newColor);
            console.log("ATTRS (col):", data.item._attrs.get('fontBackgroundColor'));
            var map = data.item._attrs;
            var newstyle = newStyle(map);
    
            viewWriter.setAttribute( 'style', newstyle, viewNode);
        }
    }}}
}


function contConv (thiseditor, className, toolbarName) {
    const modelName = toolbarName + '-container';

    thiseditor.conversion.for( 'upcast' ).elementToElement( {
        view: {
            name: 'span',
            classes: ['simple-box', className],
            //src: imgSRC
        },
        model: ( viewElement, { writer: modelWriter } ) => {
            return modelWriter.createElement( modelName, {
                class: ['simple-box', className, 'ck-widget'].join(' '),
                //fontSize: "30px"
                //src: imgSRC
            } );
        }
    } );


    thiseditor.conversion.for( 'downcast' ).elementToElement( {
        model: modelName,
        view: ( modelValue, { writer: viewWriter } ) => {
            console.log("CREATE", modelName);

            const section = viewWriter.createContainerElement( 'span', {
                class: ['simple-box', className].join(' '),
                //style: "font-size: 30px;"
                //style: "color: #0000ff;"
            });

            return toWidget( section, viewWriter, { label: 'simple box widget' } );
            
        },
    } );

    
    thiseditor.conversion.for( 'downcast' ).add( dispatcher => {
        dispatcher.on( 'attribute', ( evt, data, conversionApi ) => {
            // Convert <integralBox-container> attributes only.
            if ( data.item.name != modelName ) {
                return;
            }
            console.log("NEW ATTRIBUTES for container!!!", data.attributeKey); //data.attributeNewValue

            const viewWriter = conversionApi.writer;
            const viewNode = conversionApi.mapper.toViewElement( data.item );

            // In the model-to-view conversion we convert changes.
            // An attribute can be added or removed or changed.
            // The below code handles all 3 cases.
            if ( data.attributeNewValue ) {
                viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, viewNode );
            } else {
                viewWriter.removeAttribute( data.attributeKey, viewNode );
            }

            newAttributesEventContainer(data, viewWriter, viewNode);

        } );
    } );


}

export default contConv