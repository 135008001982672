import { toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';

function upCast (type, modelName, classNames){
    var obj = {
        model: modelName,
        view: {
            name: type,
            classes: classNames
        }
    }
    return obj
}


function downCast (type, modelName, classNames){
    var obj = {
        model: modelName,
        view: {
            name: type,
            classes: classNames
        }
    }
    return obj
}


function downCastEdit (type, modelName, classNames){
    var obj = {
        model: modelName,
        view: ( modelElement, { writer: viewWriter } ) => {
            const el = viewWriter.createEditableElement( type, { class: classNames.join(' ') } );

            return toWidgetEditable( el, viewWriter );
        }
    }
    return obj
}


function smallboxConv (thiseditor, className, toolbarName, boxName, boxClass) {
    thiseditor.conversion.for( 'upcast' ).elementToElement(
        upCast('span', toolbarName+'-'+boxName, ['small-box', boxClass, className]) 
    );
    thiseditor.conversion.for( 'dataDowncast' ).elementToElement(
        downCast('span', toolbarName+'-'+boxName, ['small-box', boxClass, className])
    );
    thiseditor.conversion.for( 'editingDowncast' ).elementToElement(
        downCastEdit('span', toolbarName+'-'+boxName, ['small-box', boxClass, className])
    );
}


export default smallboxConv