function iconSchema (thiseditor, toolbarName){

    thiseditor.model.schema.addAttributeCheck( context => {
        if ( context.endsWith( toolbarName+'-icon' ) ) {
            return true; // allow attributes for this element
        }
    } );

    thiseditor.model.schema.register( toolbarName+'-icon', {
        // Behaves like a self-contained object (e.g. an image).
        isObject: true,
        isLimit: true,
        allowAttributesOf: '$text',
        allowIn: toolbarName+'-container'
    });

}

export default iconSchema