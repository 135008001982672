/*
$( document ).ready(function() {
    // add listeners
    document.getElementById("openlistofsymbols").addEventListener('click', makelist, false);
    
});
*/

import {properties as simpleIntegral} from './config/simpleIntegral';
import {properties as simpleSum} from './config/simpleSum';

var symobjects = [
    simpleIntegral,
    simpleSum
];

import './symbolsstyle.css';

function makelistitem(name, img, id){
    img = img.replace(/"/g, "&quot;");
    return '<div class="listel" id="'+id+'"><div class="symlisticonbox"><img src="'+img+'"></div><div class="symlistnamebox">'+name+'</div></div>'
}

function syms(){
    // return all available symbols as an object
    var symbols = {};
    symobjects.forEach(sym=> {
        symbols[sym.toolbarName] = sym;
    });
    /*
    var symbols = {
        "rabbitPic": {
            displayName: "Rabbit",
            imgSRC: "rabbit.jpeg",
            toolbarName: "rabbitPic"
        },
        "frogPic": {
            displayName: "Frog",
            imgSRC: "frog.jpg",
            toolbarName: "frogPic"
        }
    }
    */
    return symbols
}

function makelist(){
    // make list of symbols in element "listofsymbols"

    var listbox = $('#listofsymbols');
    var symbols = syms();

    for (const sym of Object.entries(symbols)) {
        var name = sym[1].displayName;
        var img = sym[1].imgSRC;
        var id = sym[1].toolbarName;
        listbox.append(makelistitem(name, img, id))
    }

}

export {makelistitem, makelist, syms}