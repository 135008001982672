import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import Command from '@ckeditor/ckeditor5-core/src/command';
import makesvg from '../ui/makeSVG';

import buttonConstructor from '../ui/buttonConstructor1';
import RefreshCommand from '../ui/refreshCommand1';
import newPath from '../ui/newPath1';
import contSchema from '../defineSchemas/containerSchema1';
import contConv from '../defineConverters/containerConverter1';
import iconSchema from '../defineSchemas/iconSchema1';
import iconConv from '../defineConverters/iconConverter1';
import smallboxSchema from '../defineSchemas/smallboxSchema1';
import smallboxConv from '../defineConverters/smallboxConverter1';

const className = 'sum';
const toolbarName = 'sumBox';
const displayName = 'Sum';
const execCommand = 'insertSimpleSum';
const latexName = '\\sum{}';
//console.log("imgSRC:", makesvg(latexName));
const imgSRC = 'data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="2.766ex" height="2.262ex" role="img" focusable="false" viewBox="0 -750 1222.7 1000" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" style="vertical-align: -0.566ex;"%3e%3cdefs%3e%3cpath id="MJX-2-TEX-SO-2211" d="M61 748Q64 750 489 750H913L954 640Q965 609 976 579T993 533T999 516H979L959 517Q936 579 886 621T777 682Q724 700 655 705T436 710H319Q183 710 183 709Q186 706 348 484T511 259Q517 250 513 244L490 216Q466 188 420 134T330 27L149 -187Q149 -188 362 -188Q388 -188 436 -188T506 -189Q679 -189 778 -162T936 -43Q946 -27 959 6H999L913 -249L489 -250Q65 -250 62 -248Q56 -246 56 -239Q56 -234 118 -161Q186 -81 245 -11L428 206Q428 207 242 462L57 717L56 728Q56 744 61 748Z"%3e%3c/path%3e%3c/defs%3e%3cg stroke="currentColor" fill="currentColor" stroke-width="0" transform="scale(1,-1)"%3e%3cg data-mml-node="math"%3e%3cg data-mml-node="mo"%3e%3cuse data-c="2211" xlink:href="%23MJX-2-TEX-SO-2211"%3e%3c/use%3e%3c/g%3e%3cg data-mml-node="TeXAtom" data-mjx-texclass="ORD" transform="translate(1222.7,0)"%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e';

export default class EverythingYouNeedToKnowAboutThisSymbol extends Plugin {
    static get requires() {
        return [ Widget ];
    }

    init() {
        // define all components of this symbol

        contSchema(this.editor, toolbarName);
        contConv(this.editor, className, toolbarName);

        smallboxSchema(this.editor, toolbarName, 'upperBox');
        smallboxConv(this.editor, className, toolbarName, 'upperBox', 'upper-box');

        smallboxSchema(this.editor, toolbarName, 'lowerBox');
        smallboxConv(this.editor, className, toolbarName, 'lowerBox', 'lower-box');

        smallboxSchema(this.editor, toolbarName, 'textBox');
        smallboxConv(this.editor, className, toolbarName, 'textBox', 'text-box');

        iconSchema(this.editor, toolbarName);
        iconConv(this.editor, className, latexName, toolbarName, imgSRC);

        // insert symbol
        this.editor.commands.add( execCommand, new InsertSymbol( this.editor ) );

        // create a button for this symbol
        buttonConstructor.call(this, this.editor, toolbarName, displayName, execCommand);

    }

}


function createSymbol( writer ) {
    // construct this symbol, component by component

    const symbolContainer = writer.createElement( toolbarName+'-container');

    writer.append( writer.createElement( toolbarName+'-icon' ), symbolContainer );
    writer.append( writer.createElement( toolbarName+'-lowerBox' ), symbolContainer );
    writer.append( writer.createElement( toolbarName+'-upperBox' ), symbolContainer );
    writer.append( writer.createElement( toolbarName+'-textBox' ), symbolContainer );

    return symbolContainer;
}


class InsertSymbol extends Command {
    // insert this symbol in the editor

    execute() {
        this.editor.model.change( writer => {
            // insert symbol at the current selection position in a way that will result in creating a valid model structure
            this.editor.model.insertContent( createSymbol( writer ) );

            // move cursor to first small-box
            var path = this.editor.model.document.selection.focus.path;
            var newpath = newPath(path);
            var root = this.editor.model.document.getRoot();
            var newpos = writer.createPositionFromPath(root, newpath);
            writer.setSelection( newpos );
            //writer.setAttribute( 'color', 'red', symbolBox );

        } );
        console.log("INSERT NEW SYMBOL");
        //FindSymbols(); // update list of symbols
    }
    //refresh() { new RefreshCommand(this, toolbarName) }
    // since all symbols are allowed in $text, and text is allowed everywhere in the editor, it is not necessary to refresh "isEnabled"

}

const properties = {
    className: className,
    toolbarName: toolbarName,
    displayName: displayName,
    execCommand: execCommand,
    latexName: latexName,
    imgSRC: imgSRC
}

export {properties}