import {makelistitem, makelist, syms} from "../symbols/makesymbollist.js"
import {makesymlayoutlistitem, makelaylist, symlayouts} from "./makesymlayoutlist.js"
import { addkeycontenttoCK } from "./keyboardscript.js";

var ACTIVESYM = null;
var ACTIVEBTN = null;
const SYMS = syms();
var SYMLAYOUT = {};

/*
$( document ).ready(function() {
    initbuilderbuttons();
});
*/

function initbuilderbuttons(){
    // add listeners
    document.getElementById("openlistofsymbols").addEventListener('click', init, false);
    document.getElementById("openlistofsymlayouts").addEventListener('click', initLayoutList, false);
    document.getElementById("savelayout").addEventListener('click', savelayout, false);
}

function init(){
    // start keyboard builder
    makelist()
    var symbuttons = document.querySelectorAll('#listofsymbols .listel');

    symbuttons.forEach(sym => {
        sym.addEventListener('click', buttonClicked);
    });
    document.getElementById("deletesym").addEventListener('click', buttonClicked);
}

function initLayoutList(){
    makelaylist()
    var laybuttons = document.querySelectorAll('#listofsymlayouts .listel');

    laybuttons.forEach(layout => {
        layout.addEventListener('click', updatelayout);
    });
}

function buttonClicked(e){
    var sym = $(e.currentTarget);
    if (sym.hasClass( "active" )) {
        sym.removeClass( "active" );
        ACTIVESYM = null;
    } else {
        //another button than the active one was clicked
        removeActiveSym();
        sym.addClass( "active" )
        ACTIVESYM = sym.attr('id');
    }
}

function addSymToButton(button, symID){
    if (symID != "deletesym") {
        var img = SYMS[symID].imgSRC;
        img = img.replace(/"/g, "&quot;");
        var html = '<img id="'+symID+'" class="keysym" src="'+img+'">';
        button[0].innerHTML = html;
    } else {
        // user wants to delete symbol from this button
        button[0].innerHTML = "";
    }
}

function addSymToLayout(button, symID){
    var buttonID = button[0].parentNode.parentNode.id;
    var symbolbox = button[0].classList[0];
    if (!SYMLAYOUT[buttonID]) {
        // no data on this button
        SYMLAYOUT[buttonID] = {}
    }
    if (symID != "deletesym") {
        SYMLAYOUT[buttonID][symbolbox] = symID;
    } else {
        // user wants to delete symbol from this button
        delete SYMLAYOUT[buttonID][symbolbox]
    }
}

function removeActiveSym(){
    if (ACTIVESYM != null) {
        $("#"+ACTIVESYM).removeClass( "active" );
        ACTIVESYM = null;
    }
}

function keyboxClicked(e){
    var button = $(e.currentTarget);
    if (ACTIVESYM != null) {
        // insert this into key!
        addSymToButton(button, ACTIVESYM);
        addSymToLayout(button, ACTIVESYM);
        removeActiveSym();
    } else {
        // we just clicked a button, not building layout
        addkeycontenttoCK(button)
    }
}

function savelayout(){
    // for now, just print current layout
    console.log(SYMLAYOUT);
    var symlayoutstr = "{"

    for (const key of Object.entries(SYMLAYOUT)) {
        var keycontent = key[0] + ": {"
        var empty = true;
        for (const sym of Object.entries(key[1])) {
            keycontent = keycontent + sym[0] + ': "' + sym[1] + '", '
            empty = false;
        }
        if (empty == false){
            keycontent = keycontent.slice(0, -2); // remove comma from last row, but not if last row does not exist
        } 
        keycontent = keycontent + "}, "
        if (empty == false){
            symlayoutstr = symlayoutstr + keycontent  // no need to add this if key is empty
        }
    }
    symlayoutstr = symlayoutstr.slice(0, -2); // remove comma from last row

    symlayoutstr = symlayoutstr + "}"
    $("#outputbox").html(symlayoutstr);
}

function resetSymlayout(){
    SYMLAYOUT = {}
    var symboxestoreset = ["k1", "k3", "k4", "k5"];
    symboxestoreset.forEach(id => {
        var symboxes = $("#keyboard ."+id);
        symboxes.each(function() {
            if (!$(this).parent().parent().hasClass("uibutton")){
                // don't empty ui buttons
                $( this ).empty();
            }
        });
    });
}

function hasTallEnter(){
    // return true if current keyboard has tall enter. otherwise false
    var q = $("#keyscontainer .key.upperEnter");
    if (q.length > 0){
        return true
    } else {
        return false
    }
}

function updatelayout(e){
    // insert new symbols layout into keyboard
    resetSymlayout();
    var clicked = $(e.currentTarget);
    var id = clicked[0].id;  // id of layout
    var layout = symlayouts[id];

    for (const key of Object.entries(layout)) {
        var keyID = key[0];

        for (const symbolbox of Object.entries(key[1])) {
            var symbolboxname = symbolbox[0];
            var symID = symbolbox[1];
            var button = $("#keyboard #"+keyID+" ."+symbolboxname);  // where to insert the symbol
            if (button.parent().parent().hasClass("uibutton") || button.length == 0) {
                // symlayout is probably made for another enter button size. Let's fix this.
                button = $("#keyboard .buttonToMove ."+symbolboxname);
            }
            addSymToButton(button, symID);
        }

    }
    Object.assign(SYMLAYOUT, symlayouts[id]); // we don't want to modify the symlayouts object. That should only be done when we press "save".
}


export {keyboxClicked, initbuilderbuttons}