import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

function buttonConstructor (editor, toolbarName, displayName, execCommand){
    const t = editor.t;
    // The button must be registered among the UI components of the editor to be displayed in the toolbar.
    editor.ui.componentFactory.add( toolbarName, locale => {
        const command = editor.commands.get( execCommand ); // The state of the button will be bound to the widget command.
        const buttonView = new ButtonView( locale ); // The button will be an instance of ButtonView.
        buttonView.set( {
            // The t() function helps localize the editor. All strings enclosed in t() can be
            // translated and change when the language of the editor changes.
            label: t( displayName ),
            withText: true,
            tooltip: true
        } );
        buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' ); // Bind the state of the button to the command.
        this.listenTo( buttonView, 'execute', () => editor.execute( execCommand ) ); // Execute the command when the button is clicked (executed).
        return buttonView;
    });
}

export default buttonConstructor