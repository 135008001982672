function smallboxSchema (thiseditor, toolbarName, boxName){

    thiseditor.model.schema.addChildCheck( (context, childDefinition) => {
        if ( context.endsWith( toolbarName+'-'+boxName ) && childDefinition.name == 'mathBox-container' ) {
            return false; // disallow mathBox in this element
        }
    } );

    thiseditor.model.schema.register( toolbarName+'-'+boxName, {
        // Cannot be split or left by the caret.
        isLimit: true,
        // Allow content which is allowed in blocks (i.e. text with attributes).
        allowContentOf: '$block',
        allowIn: toolbarName+'-container'
    });

}

export default smallboxSchema