// return element style attributes as a string
function newStyle(map){

    var newstyle = "";
    map.forEach((value, key) => {
        console.log(key);
        if (key === 'fontColor'){
            newstyle = newstyle + 'color:' + value + ";";
        };
        if (key === 'fontSize'){
            newstyle = newstyle + 'font-size:' + value + ";";
        };
        if (key === 'fontBackgroundColor'){
            newstyle = newstyle + 'background-color:' + value + ";";
        };
        console.log(newstyle);
    } )

    return newstyle

}

export default newStyle