import layouts from './layoutsfolder/layouts.js';
import symbols from './keyboardsymbols.js';
import {keyboxClicked} from "./keyboardbuilder.js";
import './keyboardstyle.css';
import keyboardkey from "./layoutsfolder/keycodes";
import {syms} from "../symbols/makesymbollist.js"

const SYMS = syms();
window.keyboardactive = false;

console.log("Load keyboard script");

function loadKeyboard(){
    console.log("Insert keyboard");
    fetch('keyboard.html')
	.then(response=> response.text())
	.then(text=> document.getElementById('keyboardcontainer').innerHTML = text)
    .then( // extra delay because idk
        setTimeout(function() {
            initKeyboard()
        }, 100)
        )
}

function initKeyboard(){
    updateWidth();
    $('#keyboard').css('visibility', 'visible');
    changestatusofkeyboard(); // make active
    // add listeners
    document.getElementById("makeTallEnter").addEventListener('click', makeTallEnter, false);
    document.getElementById("makeWideEnter").addEventListener('click', makeWideEnter, false);
    document.getElementById("openKeyboard").addEventListener('click', resetKeyboard, false);
    
    var layoutbuttons = document.getElementsByClassName("layout");
    for (var i = 0; i < layoutbuttons.length; i++) {
        layoutbuttons[i].addEventListener('click', changelayout, false);
    }

    // store copy of unmodified keyboard
    window.emptykeyboard = $( "#hiddenkeyscontainer" ).html();
    $( "#hiddenkeyscontainer" ).empty()

    // ui buttons
    window.shift = false;
    window.ctrl = false;
    window.alt = false;
    window.lastuipress = 0;
    window.doubleclicklimit = 300; //ms to count as double click
    window.keepactive = false;
    uibuttons();
    keypress();
}

function updateWidth(){
    // update width of keyboard
    var refwidth = $( "#ref" ).width();
    if (typeof(refwidth) == "string"){
        refwidth = parseFloat( refwidth.substring(0, refwidth.length-1) );
    }
    $('#keyboard').css('transform', 'scale(' + refwidth/1000 + ')');
    //console.log(refwidth);
}

function updateHeight(){
    // update heigth of keyboard container
    var refwidth = $( "#ref" ).width();
    var keyboardheight = $( "#keyboard" ).height();
    var keyboardtrueheight = keyboardheight * refwidth/1000;
    console.log("Set keyboard container height:", keyboardtrueheight)
    $("#keyboardcontainer").css('height', keyboardtrueheight + 'px');
}

$( window ).resize( function() {
    updateWidth();
    updateHeight();
});


function layoutConverter(name){
    // Return array of layout of keyboard with name "name"
    const layout = layouts[name];
    const normal = layout.normal;
    var layoutlist = [];
    
    for (var row = 0; row < normal.length; row++) {
        var thisrow = normal[row].split(" ");
        layoutlist.push(thisrow);
    }

    console.log(layoutlist);
    return layoutlist
}

function makeTallEnter(){
    $( ".buttonToMove" ).insertAfter( $( "#311" ) );  // move button down
    $( ".buttonToMove" ).removeClass( "r2" );
    $( ".buttonToMove" ).addClass( "r3" );
    var upperEnter = '<div class="key upperEnter uibutton" id="ue"><div class="keycontent"></div></div>';
    $( upperEnter ).insertAfter( $( "#213" ) );
    $( ".enter" ).addClass( "lowerEnter" );  // enter button becomes lower part of enter
    $( ".enter" ).removeClass( "enter" );
}

function makeWideEnter(){
    $( ".upperEnter" ).remove(); // remove upper enter
    $( ".buttonToMove" ).insertAfter( $( "#213" ) );  // move button up
    $( ".buttonToMove" ).removeClass( "r3" );
    $( ".buttonToMove" ).addClass( "r2" );
    $( ".lowerEnter" ).addClass( "enter" );  // lower enter button becomes the only enter
    $( ".lowerEnter" ).removeClass( "lowerEnter" );
}

/*
$( document ).ready(function() {
    console.log("Try to load keyboard")
    loadKeyboard();
});
*/

function resetKeyboard(){
    $( "#keyscontainer" ).empty();
    $( "#keyscontainer" ).append( window.emptykeyboard );
    updateHeight();
}

function changelayout(){
    resetKeyboard();
    const name = $(this).attr('id');
    var layoutlist = layoutConverter(name);

    var wideEnter = true;

    for (var i = 0; i < layoutlist.length; i++) {
        var row = layoutlist[i];
        
        for (var j = 0; j < row.length; j++) {

            if (i == 1) {
                if (row.length == 13) {
                    wideEnter = false;
                }
            }

            var symbol = row[j];
            var symbolhtml = symbols.kuisymbols[symbol];
            var ui = false;

            if(typeof symbolhtml  == "undefined"){
                symbolhtml = symbols.ksymbols[symbol]
                if(typeof symbolhtml  == "undefined"){
                    symbolhtml = symbol;
                }
            } else {
                // symbol is a ui symbol
                ui = true;
            }

            var symbolname = symbol;

            var keyID = '#' + (i+1).toString() + (j+1).toString();
            var key = $( keyID );

            if(symbolname == "{space}"){
                key.addClass( "spacebutton" );
            }

            //var symbolbox = $( '#keyboard' ).find(keyID);
            if(ui == true){
                var symbolbox = $( keyID ).find('.k1');
                key.addClass( "uibutton" );
            } else {
                var symbolbox = $( keyID ).find('.k2');
            }

            if ( i == 1 && j == 13 && wideEnter == true) {
                // wide enter layout. This button is the button to be moved if changed to tall enter layout.
                key.addClass( "buttonToMove" );
                key.removeClass( "upperEnter" );
            }

            if ( i == 2 && j == 11 && wideEnter == true) {
                // wide enter layout. This button is enter.
                key.addClass( "enter" );
            }

            if ( i == 2 && j == 11 && wideEnter == false) {
                // tall enter layout. This button is the button to be moved if changed to wide enter layout.
                key.addClass( "buttonToMove" );
            }

            if ( i == 2 && j == 12 && wideEnter == false) {
                // tall enter layout. This button is lower part of enter
                key.addClass( "lowerEnter" );
            }
                
            symbolbox.val(symbolname);
            symbolbox.html(symbolhtml);

            
        }

    }

    if (wideEnter == true) {
        $( "#313" ).remove(); // this button is after enter and should not exist
    } else {
        $( "#214 div" ).empty();  // this is upper part of enter
        $( "#214" ).addClass( "uibutton" );
    }

    buttonListeners(); // layout has changed, maybe buttons have been added, idk
}

function uibuttons(){
    // this function handles what ui buttons shall do
    $( document ).on("keyup", function( e ) {
        var key = e.code || e.keyCode;

        var timenow = Date.now();
        if (timenow - window.lastuipress < window.doubleclicklimit) {
            // double click?
            if (key == 16 && window.shift == true){
                window.keepactive = true;
            } else {
            if (key == 17 && window.ctrl == true){
                window.keepactive = true;
            } else {
            if ( (key == 18 || key == 225) && window.alt == true){
                window.keepactive = true;
            }
            }}
        } else {
            // maybe trying to deactivate active ui button by clicking it again?
            if (key == 16 && window.shift == true) {
                window.keepactive = false;
            }
            if (key == 17 && window.ctrl == true) {
                window.keepactive = false;
            }
            if ( (key == 18 || key == 225) && window.alt == true) {
                window.keepactive = false;
            }
        }

        if (key == 16 && window.shift == false) {
            // shift was pressed
            $(".k3").addClass('active');
            window.shift = true;
            window.lastuipress = Date.now();
            window.keepactive = false;
            if (window.ctrl == true) {
                $(".k4").removeClass('active');
                window.ctrl = false;
            }
            if (window.alt == true) {
                $(".k5").removeClass('active');
                window.alt = false;
            }
            e.preventDefault();
        } else {
        if (key == 17 && window.ctrl == false ) {
            // ctrl was pressed
            $(".k4").addClass('active');
            window.ctrl = true;
            window.lastuipress = Date.now();
            window.keepactive = false;
            if (window.shift == true) {
                $(".k3").removeClass('active');
                window.shift = false;
            }
            if (window.alt == true) {
                $(".k5").removeClass('active');
                window.alt = false;
            }
            e.preventDefault();
        } else {
        if ( (key == 18 || key == 225) && window.alt == false ) {
            // alt or alt gr was pressed
            $(".k5").addClass('active');
            window.alt = true;
            window.lastuipress = Date.now();
            window.keepactive = false;
            if (window.ctrl == true) {
                $(".k4").removeClass('active');
                window.ctrl = false;
            }
            if (window.shift == true) {
                $(".k3").removeClass('active');
                window.shift = false;
            }
            e.preventDefault();
        } else {
            // no ui button was pressed or same button was pressed again
            if (window.keepactive == false){
                if (window.ctrl == true) {
                    $(".k4").removeClass('active');
                    window.ctrl = false;
                }
                if (window.shift == true) {
                    $(".k3").removeClass('active');
                    window.shift = false;
                }
                if (window.alt == true) {
                    $(".k5").removeClass('active');
                    window.alt = false;
                }
            }
        }}}
        
    });
}

function buttonListeners(){
    var keybuttons = document.querySelectorAll('.keysymbolbox');
    keybuttons.forEach(key => {
        key.removeEventListener('click', keyboxClicked);  // not sure if listener is already applied
        key.addEventListener('click', keyboxClicked);
    });
}

function changestatusofkeyboard(){
    if (window.keyboardactive) {
        $("#keyboard").removeClass('active');
        window.keyboardactive = false;
    } else {
        $("#keyboard").addClass('active');
        window.keyboardactive = true;
    }
}

function keypress(){
    // what to do if key is pressed
    //https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent
    $( document ).on("keydown", function( e ) {
        var keynum = e.code || e.keyCode;
        console.log("You pressed", keynum);
        if (keynum == 27){
            // Escape was pressed
            changestatusofkeyboard()
        }
        var keyID = keyboardkey[keynum];
        if (keyID && window.keyboardactive) {
            // only do this if keyID is a valid key (e.g. not enter) and only if keyboard is active 
            var key = $("#keyboard #"+keyID);
            var symboxes = key[0].firstChild.children;
            var button = $( $("#keyboard #"+keyID)[0].firstChild.firstChild ); // default, main symbox
            for (let box of symboxes){
                box = $(box);
                if (box.hasClass("active")){  // check if one of the symboxes is active
                    button = box;
                }
            }
            addkeycontenttoCK(button);
            e.preventDefault(); // stop text from printing
        } else {
            console.log("Not a valid button");
        }
    })
}

function addkeycontenttoCK(button){
    if (button[0].firstChild){
        var symbol = button[0].firstChild.id;
        console.log(symbol);
        var executeCommand = SYMS[symbol].execCommand;
        var editor = window.editor;
        editor.execute( executeCommand );
    } else {
        console.log("Empty button");
    }
    
}

export {loadKeyboard, addkeycontenttoCK}

// TODO: Make ui buttons clickable