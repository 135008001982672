import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import Command from '@ckeditor/ckeditor5-core/src/command';
import makesvg from '../ui/makeSVG';

import buttonConstructor from '../ui/buttonConstructor1';
import RefreshCommand from '../ui/refreshCommand1';
import newPath from '../ui/newPath1';
import contSchema from '../defineSchemas/containerSchema1';
import contConv from '../defineConverters/containerConverter1';
import iconSchema from '../defineSchemas/iconSchema1';
import iconConv from '../defineConverters/iconConverter1';
import smallboxSchema from '../defineSchemas/smallboxSchema1';
import smallboxConv from '../defineConverters/smallboxConverter1';

const className = 'integral';
const toolbarName = 'integralBox';
const displayName = 'Integral';
const execCommand = 'insertSimpleIntegral';
const latexName = '\\int{}';
//console.log("imgSRC:", MakeSVG(latexName));
const imgSRC = 'data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="1.757ex" height="2.514ex" role="img" focusable="false" viewBox="0 -805.5 776.7 1111" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" style="vertical-align: -0.691ex;"%3e%3cdefs%3e%3cpath id="MJX-2-TEX-SO-222B" d="M113 -244Q113 -246 119 -251T139 -263T167 -269Q186 -269 199 -260Q220 -247 232 -218T251 -133T262 -15T276 155T297 367Q300 390 305 438T314 512T325 580T340 647T361 703T390 751T428 784T479 804Q481 804 488 804T501 805Q552 802 581 769T610 695Q610 669 594 657T561 645Q542 645 527 658T512 694Q512 705 516 714T526 729T538 737T548 742L552 743Q552 745 545 751T525 762T498 768Q475 768 460 756T434 716T418 652T407 559T398 444T387 300T369 133Q349 -38 337 -102T303 -207Q256 -306 169 -306Q119 -306 87 -272T55 -196Q55 -170 71 -158T104 -146Q123 -146 138 -159T153 -195Q153 -206 149 -215T139 -230T127 -238T117 -242L113 -244Z"%3e%3c/path%3e%3c/defs%3e%3cg stroke="currentColor" fill="currentColor" stroke-width="0" transform="scale(1,-1)"%3e%3cg data-mml-node="math"%3e%3cg data-mml-node="mo" transform="translate(0 0.5)"%3e%3cuse data-c="222B" xlink:href="%23MJX-2-TEX-SO-222B"%3e%3c/use%3e%3c/g%3e%3cg data-mml-node="TeXAtom" data-mjx-texclass="ORD" transform="translate(776.7,0)"%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e';

export default class EverythingYouNeedToKnowAboutThisSymbol extends Plugin {
    static get requires() {
        return [ Widget ];
    }

    init() {
        // define all components of this symbol

        contSchema(this.editor, toolbarName);
        contConv(this.editor, className, toolbarName);

        smallboxSchema(this.editor, toolbarName, 'upperBox');
        smallboxConv(this.editor, className, toolbarName, 'upperBox', 'upper-box');

        smallboxSchema(this.editor, toolbarName, 'lowerBox');
        smallboxConv(this.editor, className, toolbarName, 'lowerBox', 'lower-box');

        smallboxSchema(this.editor, toolbarName, 'textBox');
        smallboxConv(this.editor, className, toolbarName, 'textBox', 'text-box');

        iconSchema(this.editor, toolbarName);
        iconConv(this.editor, className, latexName, toolbarName, imgSRC);

        // insert symbol
        this.editor.commands.add( execCommand, new InsertSymbol( this.editor ) );

        // create a button for this symbol
        buttonConstructor.call(this, this.editor, toolbarName, displayName, execCommand);

    }

}


function createSymbol( writer, mathBox ) {
    // construct this symbol, component by component
    
    const symbolContainer = writer.createElement( toolbarName+'-container');

    writer.append( writer.createElement( toolbarName+'-icon' ), symbolContainer );
    writer.append( writer.createElement( toolbarName+'-lowerBox' ), symbolContainer );
    writer.append( writer.createElement( toolbarName+'-upperBox' ), symbolContainer );
    writer.append( writer.createElement( toolbarName+'-textBox' ), symbolContainer );

    if (mathBox) {
        const mathContainer = writer.createElement( 'mathBox-container');
        const textbox = writer.createElement( 'mathBox-textBox' );
        writer.append( textbox, mathContainer );
        writer.append( symbolContainer, textbox );
        return mathContainer
    }

    return symbolContainer;
}


class InsertSymbol extends Command {
    // insert this symbol in the editor

    execute() {
        this.editor.model.change( writer => {
            // insert symbol at the current selection position in a way that will result in creating a valid model structure
            //console.log("Insertion selection:",this.editor.model.document.selection.getAttributes().next()); //use this to eg make symbol red when inserted in red text
            
            if ( this.editor.model.document.selection.focus.parent.name == "paragraph" ) { var mathBox = true; } // put this symbol in a math-box

            this.editor.model.insertContent( createSymbol( writer, mathBox ) );

            // move cursor to first small-box
            var path = this.editor.model.document.selection.focus.path;
            var newpath = newPath(path, mathBox);
            var root = this.editor.model.document.getRoot();
            var newpos = writer.createPositionFromPath(root, newpath);
            writer.setSelection( newpos );
            //writer.setAttribute( 'color', 'red', symbolBox );

        } );
        console.log("INSERT NEW SYMBOL");
        //FindSymbols(); // update list of symbols
    }
    //refresh() { new RefreshCommand(this, toolbarName) }
    // since all symbols are allowed in $text, and text is allowed everywhere in the editor, it is not necessary to refresh "isEnabled"

}

const properties = {
    className: className,
    toolbarName: toolbarName,
    displayName: displayName,
    execCommand: execCommand,
    latexName: latexName,
    imgSRC: imgSRC
}

export {properties}